import { toast } from "@appsmith/ads";
import { createMessage } from "@appsmith/ads-old";
import { fetchJSCollectionsForPage } from "actions/jsActionActions";
import { updateFunctionProperty } from "actions/jsPaneActions";
import {
  fetchActionsForPage,
  updateActionProperty,
} from "actions/pluginActionActions";
import type { ReduxAction } from "actions/ReduxActionTypes";
import type { ApiResponse } from "api/ApiResponses";
import { SchemaAPI, type GenerateSchemaResponse } from "ee/api/SchemaAPI";
import { CANNOT_GENERATE_SCHEMA } from "ee/constants/messages";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import type { JSAction } from "entities/JSCollection";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { getCurrentPageId } from "selectors/editorSelectors";

function* generatePluginActionSchemaSaga(action: ReduxAction<{ id: string }>) {
  const { id } = action.payload;

  try {
    const response: ApiResponse<GenerateSchemaResponse> = yield call(
      SchemaAPI.generateSchema,
      id,
    );

    yield put(
      updateActionProperty({
        id,
        field: "schema",
        value: response.data.schema,
      }),
    );
    yield put(
      updateActionProperty({
        id,
        field: "description",
        value: response.data.description,
      }),
    );

    yield put({
      type: ReduxActionTypes.GENERATE_PLUGIN_ACTION_SCHEMA_SUCCESS,
      payload: { id },
    });
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.GENERATE_PLUGIN_ACTION_SCHEMA_ERROR,
      payload: { id },
    });

    toast.show(createMessage(CANNOT_GENERATE_SCHEMA), {
      kind: "error",
    });
  }
}

function* generateJSFunctionSchemaSuccessSaga(
  reduxAction: ReduxAction<{ action: JSAction }>,
) {
  const { action } = reduxAction.payload;

  try {
    const response: ApiResponse<GenerateSchemaResponse> = yield call(
      SchemaAPI.generateSchema,
      action.id,
    );

    yield put(
      updateFunctionProperty({
        action,
        propertyName: "schema",
        value: response.data.schema,
      }),
    );
    yield put(
      updateFunctionProperty({
        action,
        propertyName: "description",
        value: response.data.description,
      }),
    );

    yield put({
      type: ReduxActionTypes.GENERATE_JS_FUNCTION_SCHEMA_SUCCESS,
      payload: { action },
    });
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.GENERATE_JS_FUNCTION_SCHEMA_ERROR,
      payload: { action },
    });

    toast.show(createMessage(CANNOT_GENERATE_SCHEMA), {
      kind: "error",
    });
  }
}

function* generateAIAgentSchemaSaga(action: ReduxAction<{ id: string }>) {
  const { id } = action.payload;
  const pageId: string = yield select(getCurrentPageId);

  try {
    yield call(SchemaAPI.generateAIAgentSchema, id);

    // Once the schema is generated on the backend,
    // we etch all actions and js functions with updated schemas
    // and replace the old actions and js functions with the updated ones
    yield all([
      put(fetchActionsForPage(pageId)),
      put(fetchJSCollectionsForPage(pageId)),
    ]);

    yield put({
      type: ReduxActionTypes.GENERATE_AI_AGENT_SCHEMA_SUCCESS,
      payload: { id },
    });
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.GENERATE_AI_AGENT_SCHEMA_ERROR,
      payload: { id },
    });

    toast.show(createMessage(CANNOT_GENERATE_SCHEMA), {
      kind: "error",
    });
  }
}

export default function* watchGenerateSchemaSagas() {
  yield all([
    takeLatest(
      ReduxActionTypes.GENERATE_PLUGIN_ACTION_SCHEMA_REQUEST,
      generatePluginActionSchemaSaga,
    ),
    takeLatest(
      ReduxActionTypes.GENERATE_JS_FUNCTION_SCHEMA_REQUEST,
      generateJSFunctionSchemaSuccessSaga,
    ),
    takeLatest(
      ReduxActionTypes.GENERATE_AI_AGENT_SCHEMA_REQUEST,
      generateAIAgentSchemaSaga,
    ),
  ]);
}
