import React, { Suspense, lazy, Component } from "react";
import { Button, Flex } from "@appsmith/ads";
import { get } from "lodash";
import ErrorBoundary from "components/editorComponents/ErrorBoundry";
import AppsmithLogo from "assets/images/appsmith_logo_square.png";
import { getDatasource } from "ce/selectors/entitiesSelector";
import { fetchAccessToken } from "./RagApiRequests";
import { connect } from "react-redux";
import { getAppsmithConfigs } from "ee/configs";
import type { AppState } from "ee/reducers";
import type {
  Integration,
  EmbeddingGenerators,
} from "@appsmith/carbon-connect";
import "./carbon-modal.css";

const CarbonConnect = lazy(async () => import("./CarbonConnect"));
const { carbonApiBasePath } = getAppsmithConfigs();

interface CarbonButtonProps {
  datasourceId: string;
  workspaceId: string;
  integrations?: Integration[];
  embeddingModel?: EmbeddingGenerators;
  onModalStateChange?: (value: boolean) => void;
}

const loadingError = <div>Failed to load CarbonConnect</div>;

const fallbackButton = (
  <Button isLoading size="md" startIcon="add-more">
    Add data
  </Button>
);

interface CarbonButtonState {
  isIntegrationsLoading: boolean;
  isCarbonModalOpen: boolean;
}

class _CarbonButton extends Component<CarbonButtonProps, CarbonButtonState> {
  state = {
    isIntegrationsLoading: false,
    isCarbonModalOpen: false,
  };

  private onCarbonModalOpen = async () => {
    const { onModalStateChange } = this.props;

    this.setState({
      isCarbonModalOpen: true,
    });

    onModalStateChange && onModalStateChange(true);
  };

  render() {
    const {
      datasourceId,
      embeddingModel,
      integrations,
      onModalStateChange,
      workspaceId,
    } = this.props;

    return (
      <ErrorBoundary fallback={loadingError}>
        <Suspense fallback={fallbackButton}>
          <Flex alignItems="center" gap="spaces-3">
            <Button
              isLoading={this.state.isIntegrationsLoading}
              kind="secondary"
              onClick={this.onCarbonModalOpen}
              size="md"
            >
              Import sources
            </Button>
          </Flex>
          {this.state.isCarbonModalOpen && (
            <CarbonConnect
              apiURL={carbonApiBasePath}
              backButtonText="Close"
              brandIcon={AppsmithLogo}
              embeddingModel={embeddingModel}
              enabledIntegrations={integrations}
              entryPoint="INTEGRATION_LIST"
              open={this.state.isCarbonModalOpen}
              orgName="Appsmith"
              setOpen={(isOpen) => {
                this.setState({ isCarbonModalOpen: isOpen as boolean });

                if (onModalStateChange) {
                  onModalStateChange(isOpen as boolean);
                }
              }}
              theme="light"
              tokenFetcher={async () =>
                fetchAccessToken(datasourceId, workspaceId)
              }
            />
          )}
        </Suspense>
      </ErrorBoundary>
    );
  }
}

export const CarbonButton = connect(
  (state: AppState, ownProps: CarbonButtonProps) => {
    const datasource = getDatasource(state, ownProps.datasourceId);

    const integrations = get(
      datasource,
      `datasourceStorages.${state.environments.currentEnvironmentDetails.id}.datasourceConfiguration.properties[1].value`,
    ) as unknown as Integration[];

    const embeddingModel = get(
      datasource,
      `datasourceStorages.${state.environments.currentEnvironmentDetails.id}.datasourceConfiguration.embeddingModel`,
    ) as unknown as EmbeddingGenerators;

    return { integrations, embeddingModel };
  },
)(_CarbonButton);
