import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import { createImmerReducer } from "utils/ReducerUtils";

export interface AiAgentReduxState {
  loadingStates: {
    isCreatingAgentInWorkspace: boolean;
  };
}

const initialState: AiAgentReduxState = {
  loadingStates: {
    isCreatingAgentInWorkspace: false,
  },
};

export const handlers = {
  [ReduxActionTypes.CREATE_AI_AGENT_FROM_WORKSPACE_INIT]: (
    draftState: AiAgentReduxState,
  ) => {
    draftState.loadingStates.isCreatingAgentInWorkspace = true;
  },
  [ReduxActionTypes.CREATE_AI_AGENT_FROM_WORKSPACE_SUCCESS]: (
    draftState: AiAgentReduxState,
  ) => {
    draftState.loadingStates.isCreatingAgentInWorkspace = false;
  },
  [ReduxActionErrorTypes.CREATE_AI_AGENT_FROM_WORKSPACE_ERROR]: (
    draftState: AiAgentReduxState,
  ) => {
    draftState.loadingStates.isCreatingAgentInWorkspace = false;
  },
};

const aiAgentReducer = createImmerReducer(initialState, handlers);

export { aiAgentReducer };
