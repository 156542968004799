export * from "ce/middlewares/RouteParamsMiddleware";
import { handler as CE_Handler } from "ce/middlewares/RouteParamsMiddleware";
import type { Middleware } from "redux";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type { ReduxAction } from "actions/ReduxActionTypes";
import type { FetchPackageResponse } from "../api/PackageApi";
import type {
  ModulesParams,
  PackageParams,
} from "../entities/URLRedirect/URLAssembly";
import urlBuilder from "../entities/URLRedirect/URLAssembly";
import type { Package } from "../constants/PackageConstants";
import { klona } from "klona";

// TODO: Fix this the next time the file is edited
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handler = (action: ReduxAction<any>) => {
  switch (action.type) {
    case ReduxActionTypes.FETCH_PACKAGE_SUCCESS: {
      const { modules, packageData }: FetchPackageResponse = action.payload;
      const modulesParams: ModulesParams = {};
      const packageParams: PackageParams = {
        basePackageId: packageData.baseId,
        packageSlug: "", // TODO (Ashit) Update slugs
      };

      modules.forEach(({ baseId }) => {
        modulesParams[baseId] = {
          baseModuleId: baseId,
          moduleSlug: "", // TODO (Ashit) Update slugs
        };
      });

      urlBuilder.setPackageParams(packageParams);
      urlBuilder.setModulesParams(() => modulesParams);
      break;
    }
    case ReduxActionTypes.CREATE_PACKAGE_FROM_WORKSPACE_SUCCESS: {
      const pkg: Package = action.payload;
      const modulesParams: ModulesParams = {};
      const packageParams: PackageParams = {
        basePackageId: pkg.baseId,
        packageSlug: "", // TODO (Ashit) Update slugs
      };

      urlBuilder.setPackageParams(packageParams);
      urlBuilder.setModulesParams(() => modulesParams);
      break;
    }
    case ReduxActionTypes.UPDATE_PACKAGE_SUCCESS: {
      const pkg: Package = action.payload;
      const packageParams: PackageParams = {
        basePackageId: pkg.baseId,
        packageSlug: "", // TODO (Ashit) Update slugs
      };

      urlBuilder.setPackageParams(packageParams);
      break;
    }
    case ReduxActionTypes.SAVE_MODULE_NAME_SUCCESS: {
      const module = action.payload;

      urlBuilder.setModulesParams((currentParams) => {
        const updatedParams = klona(currentParams);

        updatedParams[module.baseId] = {
          baseModuleId: module.baseId,
          moduleSlug: "", //TODO (Ashit) Update slugs
        };

        return updatedParams;
      });
      break;
    }
    case ReduxActionTypes.CREATE_QUERY_MODULE_SUCCESS: {
      const module = action.payload;

      urlBuilder.setModulesParams((currentParams) => {
        const updatedParams = klona(currentParams);

        updatedParams[module.baseId] = {
          baseModuleId: module.baseId,
          moduleSlug: "", //TODO (Ashit) Update slugs
        };

        return updatedParams;
      });
      break;
    }
    case ReduxActionTypes.IMPORT_PACKAGE_SUCCESS: {
      const pkg: Package = action.payload;
      const packageParams: PackageParams = {
        basePackageId: pkg.baseId,
        packageSlug: "", // TODO (Ashit) Update slugs
      };

      urlBuilder.setPackageParams(packageParams);
      break;
    }
  }
};

const routeParamsMiddleware: Middleware =
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  () => (next: any) => (action: ReduxAction<any>) => {
    CE_Handler(action);
    handler(action);

    return next(action);
  };

export default routeParamsMiddleware;
