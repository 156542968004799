export * from "ce/constants/organizationConstants";
import { organizationConfigConnection as CE_organizationConfigConnection } from "ce/constants/organizationConstants";

export const organizationConfigConnection: string[] = [
  ...CE_organizationConfigConnection,
  "connectionMaxPoolSize",
  "oidcProviderName",
  "samlProviderName",
  "isCrossSiteEmbeddingEnabled",
];

export const RESTART_POLL_TIMEOUT = 2 * 150 * 1000;
