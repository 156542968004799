import { queryEditorIdURL } from "ee/RouteBuilder";
import type { ApplicationPayload } from "entities/Application";
import type { ReduxAction } from "actions/ReduxActionTypes";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import { findDefaultPage } from "ee/sagas/ApplicationSagas";
import type { ImportTemplateResponse, Template } from "api/TemplatesApi";
import TemplatesAPI from "api/TemplatesApi";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import history from "utils/history";
import { validateResponse } from "sagas/ErrorSagas";
import { getTemplatesSelector } from "selectors/templatesSelectors";
import { ConsolidatedPageLoadApi } from "api/services";
import type { EditConsolidatedApi } from "sagas/InitSagas";
import type { ApiResponse } from "api/ApiResponses";
import { setIdeEditorViewMode } from "actions/ideActions";
import { EditorViewMode } from "IDE/Interfaces/EditorTypes";

function* createAiAgentFromWorkspaceSaga(
  action: ReduxAction<{ templateId: string; workspaceId: string }>,
) {
  const templates: Template[] = yield select(getTemplatesSelector);

  const aiAgentTemplate = templates.find(
    (template) => template.title === "AI Agent",
  );

  try {
    const response: ImportTemplateResponse = yield call(
      TemplatesAPI.importTemplate,
      aiAgentTemplate?.id as string,
      action.payload.workspaceId,
    );
    const isValid: boolean = yield validateResponse(response);

    if (isValid) {
      const defaultPage = findDefaultPage(response.data.application.pages);
      const application: ApplicationPayload = {
        ...response.data.application,
        defaultPageId: defaultPage?.id,
        defaultBasePageId: defaultPage?.baseId,
      };

      // we are using cosolidateAPI here because we want to navigate to the editor
      // with query pane opened. But we don't have that information in above response.
      // So we are using consolidateAPI to get information about query of the app
      const consolidatedApiResponse: ApiResponse<EditConsolidatedApi> =
        yield call(ConsolidatedPageLoadApi.getConsolidatedPageLoadDataEdit, {
          applicationId: application.id,
          defaultPageId: application.defaultPageId,
        });

      const isValidConsolidatedApiResponse: boolean = yield validateResponse(
        consolidatedApiResponse,
      );

      if (isValidConsolidatedApiResponse) {
        const editURL = queryEditorIdURL({
          basePageId: defaultPage?.baseId,
          baseQueryId:
            consolidatedApiResponse.data.unpublishedActions.data[0].baseId,
        });

        // This is needed so that for new instance, we open the editor in split screen mode
        yield put(setIdeEditorViewMode(EditorViewMode.SplitScreen));

        yield put({
          type: ReduxActionTypes.CREATE_AI_AGENT_FROM_WORKSPACE_SUCCESS,
        });

        history.push(editURL);
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.CREATE_AI_AGENT_FROM_WORKSPACE_ERROR,
      payload: {
        error,
      },
    });
  }
}

export default function* watchActionSagas() {
  yield all([
    takeEvery(
      ReduxActionTypes.CREATE_AI_AGENT_FROM_WORKSPACE_INIT,
      createAiAgentFromWorkspaceSaga,
    ),
  ]);
}
