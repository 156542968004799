export const BASE_PACKAGE_EDITOR_PATH = "/pkg";
export const PACKAGE_EDITOR_PATH = `${BASE_PACKAGE_EDITOR_PATH}/:basePackageId`;
export const MODULE_EDITOR_BASE_PATH = `${PACKAGE_EDITOR_PATH}/:baseModuleId`;
export const MODULE_EDITOR_PATH = `${MODULE_EDITOR_BASE_PATH}/edit`;

export const MODULE_QUERY_EDITOR_PATH = `/queries/:baseQueryId`;
export const MODULE_API_EDITOR_PATH = `/api/:baseApiId`;

export const MODULE_JS_COLLECTION_EDITOR_PATH = `/jsObjects/:baseCollectionId`;
export const PACKAGE_SETTINGS_EDITOR_PATH = "/settings";
export const PACKAGE_LIBRARIES_EDITOR_PATH = "/libraries";

/**
 * UI Package Editor Routes
 */
export const BASE_UI_PACKAGE_EDITOR_PATH = "/ui-pkg";
export const UI_PACKAGE_EDITOR_PATH = `${BASE_UI_PACKAGE_EDITOR_PATH}/:basePackageId`;
export const UI_PACKAGE_EDITOR_MODULE_PATH = `${UI_PACKAGE_EDITOR_PATH}/:baseModuleId`;
export const UI_PACKAGE_EDITOR_MODULE_EDITOR_PATH = `${UI_PACKAGE_EDITOR_MODULE_PATH}/edit`;
