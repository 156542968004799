import {
  takeLatest,
  all,
  call,
  put,
  select,
  takeEvery,
  fork,
  take,
} from "redux-saga/effects";

import ModuleApi from "ee/api/ModuleApi";
import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "ee/constants/ReduxActionConstants";
import { validateResponse } from "sagas/ErrorSagas";
import {
  getCurrentBaseModuleId,
  getCurrentModuleId,
  getCurrentProcessingModuleReferences,
  getIsModuleReferenceUpdating,
  getIsSettingUpModule,
  getModuleByBaseId,
  getModuleById,
  getModulePublicAction,
  getModuleReferences,
  getPrivateEntitiesNames,
} from "ee/selectors/modulesSelector";
import type { ApiResponse } from "api/ApiResponses";
import type {
  CreateUIModulePayload,
  FetchModuleActionsPayload,
} from "ee/actions/moduleActions";
import {
  endModuleActionTracking,
  fetchAllModuleEntityCompletion,
  initModuleCanvasLayout,
  setupModule,
  startModuleActionTracking,
  type CreateJSModulePayload,
  type CreateQueryModulePayload,
  type DeleteModulePayload,
  type SaveModuleNamePayload,
  type SetupModulePayload,
  type UpdateModuleInputsPayload,
} from "ee/actions/moduleActions";
import type { ReduxAction } from "actions/ReduxActionTypes";
import type {
  CreateModulePayload,
  CreateModuleResponse,
  FetchModuleEntitiesResponse,
  RefactorModulePayload,
} from "ee/api/ModuleApi";
import history from "utils/history";
import { currentPackageEditorURL, moduleEditorURL } from "ee/RouteBuilder";
import { PluginPackageName, type Plugin } from "entities/Plugin";
import {
  type Action,
  type CreateActionDefaultsParams,
  type CreateApiActionDefaultsParams,
} from "entities/Action";
import { createDefaultActionPayloadWithPluginDefaults } from "sagas/ActionSagas";
import type { ModuleInput, ModuleMetadata } from "ee/constants/ModuleConstants";
import {
  MODULE_TYPE,
  type Module,
  MODULE_PREFIX,
  MODULE_ENTITY_TYPE,
  MODULE_EDITOR_TYPE,
} from "ee/constants/ModuleConstants";
import type { ModulesReducerState } from "ee/reducers/entityReducers/modulesReducer";
import { getAllModules } from "ee/selectors/modulesSelector";
import { createNewModuleName } from "ee/utils/Packages/moduleHelpers";
import { createDefaultApiActionPayload } from "sagas/ApiPaneSagas";
import {
  generateDefaultInputSection,
  generateUniqueId,
} from "ee/components/InputsForm/Fields/helper";
import {
  executePageLoadActions,
  type SetActionPropertyPayload,
} from "actions/pluginActionActions";
import { createDummyJSCollectionActions } from "utils/JSPaneUtils";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import { generateDefaultJSObject } from "sagas/JSPaneSagas";
import type {
  CreateJSCollectionRequest,
  RefactorAction,
  UpdateCollectionActionNameRequest,
} from "ee/api/JSActionAPI";
import type { JSCollection } from "entities/JSCollection";
import JSActionAPI from "ee/api/JSActionAPI";
import { resetDebuggerLogs, waitForWidgetConfigBuild } from "sagas/InitSagas";
import {
  MODULE_CYCLIC_REFERENCE_ERROR,
  UPDATE_MODULE_INPUT_ERROR,
  createMessage,
} from "ee/constants/messages";
import analytics from "ee/utils/Packages/analytics";
import {
  getModulesMetadata,
  getModulesMetadataById,
} from "ee/selectors/packageSelectors";
import { type Diff, type DiffEdit } from "deep-diff";
import { initialize } from "redux-form";
import {
  API_EDITOR_FORM_NAME,
  QUERY_EDITOR_FORM_NAME,
} from "ee/constants/forms";
import { getModuleInstancesByModuleId } from "ee/selectors/moduleInstanceSelectors";
import type { ModuleInstance } from "ee/constants/ModuleInstanceConstants";
import { ModuleInstanceCreatorType } from "ee/constants/ModuleInstanceConstants";
import type {
  BulkAddRemoveModuleInstancePayload,
  BulkAddRemoveModuleInstanceResponse,
} from "ee/api/ModuleInstanceApi";
import {
  getShowQueryModule,
  getShowUIModule,
} from "ee/selectors/moduleFeatureSelectors";
import { difference, find, flattenDeep, get, keyBy, set } from "lodash";
import { klona } from "klona/json";
import equal from "fast-deep-equal";
import ModuleInstanceApi from "ee/api/ModuleInstanceApi";
import type { FetchPackageResponse } from "ee/api/PackageApi";
import { extractIdentifierInfoFromCode } from "@shared/ast";
import { getEntityNameAndPropertyPath } from "ee/workers/Evaluation/evaluationUtils";
import { getDynamicBindings, isDynamicValue } from "utils/DynamicBindingUtils";
import { toast } from "@appsmith/ads";
import { flattenDSL, nestDSL } from "@shared/dsl/src/transform/lib";
import createBaseModuleDSL from "ee/utils/Packages/createBaseModuleDSL";
import type { NestedDSL } from "@shared/dsl";
import type { FeatureFlags } from "ee/entities/FeatureFlag";
import { selectFeatureFlags } from "ee/selectors/featureFlagsSelectors";
import { getEntityURL } from "ee/pages/PackageIDE/utils/getEntityUrl";
import { objectKeys } from "@appsmith/utils";
import type { WidgetProps } from "@shared/dsl/src/migrate/types";
import FocusRetentionSaga from "sagas/FocusRetentionSaga";
import {
  handleJSModuleEntityRedirect,
  handleQueryModuleEntityRedirect,
} from "./packageIDESagas";

interface ModuleLayout {
  dsl: NestedDSL<unknown>;
  layoutOnLoadActions: string[];
}

interface ReturnGenerateJSModuleInputs {
  isInputsUpdated: boolean;
  inputsForm: Module["inputsForm"];
}

interface ReferenceReturnType {
  isError: boolean;
  references: Set<string>;
}

function hasInputNameChanged(diff?: Diff<unknown, unknown>[]) {
  if (diff && diff[0].kind === "E") {
    const change = diff[0];
    const key = (change.path || [])?.slice(-1)[0] || "";

    return key === "label";
  }

  return false;
}

function inputNameDiff(diff: DiffEdit<string, string>) {
  return {
    oldName: diff.lhs,
    newName: diff.rhs,
  };
}

function findInputIdFor(inputsForm: Module["inputsForm"], name: string) {
  const input = inputsForm[0].children.find((inp) => inp.label === name);

  return input?.id;
}

function mapByName(modules: ModulesReducerState) {
  return Object.values(modules).reduce(
    (acc, module) => {
      acc[module.name] = module;

      return acc;
    },
    {} as Record<string, ModulesReducerState[string]>,
  );
}

export function detectCyclicDependency(
  dependencyMap: Record<string, Set<string>>,
) {
  const cycles: string[][] = [];

  function dfs(node: string, path: string[]): void {
    if (path.includes(node)) {
      const cycle = [...path.slice(path.indexOf(node)), node];

      cycles.push(cycle);

      return;
    }

    path.push(node);

    const neighbors = dependencyMap[node] || new Set<string>();

    for (const neighbor of neighbors) {
      dfs(neighbor, path);
    }

    path.pop();
  }

  for (const node in dependencyMap) {
    dfs(node, []);
  }

  return cycles;
}

export function* extractReferencesFromCodeSaga(code: string) {
  const modules: ModulesReducerState = yield select(getAllModules);
  const moduleNames = Object.values(modules).map((m) => m.name);
  const matchingReferences: Set<string> = new Set();
  const { isError, references } = extractIdentifierInfoFromCode(code, 2);

  const rootReferences = references.map(
    (ref) => getEntityNameAndPropertyPath(ref).entityName,
  );

  moduleNames.forEach((moduleName) => {
    if (rootReferences.includes(moduleName)) {
      matchingReferences.add(moduleName);
    }
  });

  return {
    references: matchingReferences,
    isError,
  };
}

export function* waitForSetupModule() {
  const isSettingUpModule: boolean = yield select(getIsSettingUpModule);

  if (isSettingUpModule) {
    const finishedAction: ReduxAction<unknown> = yield take([
      ReduxActionTypes.SETUP_MODULE_SUCCESS,
      ReduxActionErrorTypes.SETUP_MODULE_ERROR,
    ]);

    if (finishedAction.type === ReduxActionErrorTypes.SETUP_MODULE_ERROR) {
      return false;
    }
  }

  return true;
}

export function* waitForUpdatingModuleReferences() {
  const isModuleReferenceUpdating: boolean = yield select(
    getIsModuleReferenceUpdating,
  );

  if (isModuleReferenceUpdating) {
    const finishedAction: ReduxAction<unknown> = yield take([
      ReduxActionTypes.MODIFY_MODULE_REFERENCE_BY_NAME_SUCCESS,
      ReduxActionErrorTypes.MODIFY_MODULE_REFERENCE_BY_NAME_ERROR,
    ]);

    if (
      finishedAction.type ===
      ReduxActionErrorTypes.MODIFY_MODULE_REFERENCE_BY_NAME_ERROR
    ) {
      return false;
    }
  }

  return true;
}

export function* modifyModuleReferencesByNameSaga() {
  try {
    yield put({ type: ReduxActionTypes.MODIFY_MODULE_REFERENCE_BY_NAME_INIT });
    const isModuleSetupSuccess: boolean = yield call(waitForSetupModule);

    if (!isModuleSetupSuccess) {
      yield put({
        type: ReduxActionErrorTypes.MODIFY_MODULE_REFERENCE_BY_NAME_ERROR,
      });

      return;
    }

    const modules: ModulesReducerState = yield select(getAllModules);
    const moduleByName = mapByName(modules);
    const currentModuleId: string = yield select(getCurrentModuleId);
    const moduleInstances: ModuleInstance[] = yield select(
      getModuleInstancesByModuleId,
      currentModuleId,
    );
    const moduleInstancesNames = moduleInstances.map(({ name }) => name);
    const moduleInstanceByModuleId = keyBy(moduleInstances, "sourceModuleId");
    const prevProcessingModuleReferences: Set<string> = klona(
      (yield select(
        getCurrentProcessingModuleReferences,
      )) as unknown as Set<string>,
    );
    const privateEntitiesNames: string[] = yield select(
      getPrivateEntitiesNames,
    );

    const processedNames = new Set<string>();
    const moduleReferences: ReturnType<typeof getModuleReferences> =
      yield select(getModuleReferences);
    // moduleReferences is map of moduleId and referenced modules names
    // this converts to a map of module name and referenced module names
    const namedModuleReferences = Object.entries(moduleReferences).reduce(
      (curr, [moduleId, references]) => {
        const module = modules[moduleId];

        curr[module.name] = references;

        return curr;
      },
      {} as Record<string, Set<string>>,
    );
    const currentModuleReferences =
      moduleReferences[currentModuleId] || new Set();
    const cyclicPaths = detectCyclicDependency(namedModuleReferences);

    if (cyclicPaths.length > 0) {
      const path = cyclicPaths?.[0].join(" -> ");
      const message = createMessage(MODULE_CYCLIC_REFERENCE_ERROR, path);

      yield put({
        type: ReduxActionTypes.SET_HAS_CYCLIC_MODULE_REFERENCE,
        payload: {
          hasCyclicModuleReference: true,
        },
      });

      toast.show(message, {
        kind: "error",
      });
    } else {
      yield put({
        type: ReduxActionTypes.SET_HAS_CYCLIC_MODULE_REFERENCE,
        payload: {
          hasCyclicModuleReference: false,
        },
      });
    }

    const nodesAffectedInCyclicPaths = flattenDeep(cyclicPaths);

    const add = difference([...currentModuleReferences], moduleInstancesNames);
    const remove = difference(moduleInstancesNames, [
      ...currentModuleReferences,
    ]);

    if (add.length === 0 && remove.length === 0) {
      yield put({
        type: ReduxActionTypes.MODIFY_MODULE_REFERENCE_BY_NAME_SUCCESS,
        payload: {
          data: {
            add: [],
            remove: [],
          },
        },
      });

      return;
    }

    // Set the names that are currently being processed i.e the payload
    yield put({
      type: ReduxActionTypes.SET_CURR_PROCESSING_MODULE_REFERENCE,
      payload: {
        names: [...add, ...remove],
      },
    });

    const payload: BulkAddRemoveModuleInstancePayload = {
      contextId: currentModuleId,
      contextType: ModuleInstanceCreatorType.MODULE,
      add: [],
      remove: [],
    };

    for (const addModuleName of add) {
      const module = moduleByName[addModuleName];

      if (prevProcessingModuleReferences.has(addModuleName)) {
        continue;
      }

      processedNames.add(addModuleName);

      if (
        privateEntitiesNames.includes(addModuleName) ||
        nodesAffectedInCyclicPaths?.includes(addModuleName)
      ) {
        continue;
      }

      payload.add.push({
        sourceModuleId: module.id,
        name: module.name,
      });
    }

    for (const removeModuleName of remove) {
      const module = moduleByName[removeModuleName];
      const moduleInstance = moduleInstanceByModuleId[module?.id];

      if (prevProcessingModuleReferences.has(removeModuleName)) {
        continue;
      }

      processedNames.add(removeModuleName);
      payload.remove.push(moduleInstance.id);
    }

    if (payload.add.length > 0 || payload.remove.length > 0) {
      const response: ApiResponse<BulkAddRemoveModuleInstanceResponse> =
        yield call(ModuleInstanceApi.bulkAddRemoveModuleInstances, payload);

      const isValidResponse: boolean = yield validateResponse(response);

      if (isValidResponse) {
        yield put({
          type: ReduxActionTypes.MODIFY_MODULE_REFERENCE_BY_NAME_SUCCESS,
          payload: {
            data: response.data,
          },
        });
      }
    } else {
      yield put({
        type: ReduxActionTypes.MODIFY_MODULE_REFERENCE_BY_NAME_SUCCESS,
        payload: {
          data: {
            add: [],
            remove: [],
          },
        },
      });
    }

    yield put({
      type: ReduxActionTypes.UNSET_CURR_PROCESSING_MODULE_REFERENCE,
      payload: {
        names: [...processedNames],
      },
    });

    yield put({
      type: ReduxActionTypes.GENERATE_DUMMY_MODULE_INSTANCES_INIT,
    });

    //  Wait for the dummy entities to be generated
    yield take([
      ReduxActionTypes.GENERATE_DUMMY_MODULE_INSTANCES_SUCCESS,
      ReduxActionErrorTypes.GENERATE_DUMMY_MODULE_INSTANCES_ERROR,
    ]);

    yield put({
      type: ReduxActionTypes.FETCH_ALL_MODULE_ENTITY_COMPLETION,
    });
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.MODIFY_MODULE_REFERENCE_BY_NAME_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* deleteModuleSaga(action: ReduxAction<DeleteModulePayload>) {
  try {
    const currentUrl = window.location.pathname;
    const featureFlags: FeatureFlags = yield select(selectFeatureFlags);
    const isNewPackageExplorerEnabled =
      featureFlags.release_segmented_package_explorer_enabled;
    const module: Module = yield select(getModuleById, action.payload.id);
    const response: ApiResponse = yield call(
      ModuleApi.deleteModule,
      action.payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.DELETE_MODULE_SUCCESS,
        payload: action.payload,
      });
      yield call(FocusRetentionSaga.handleRemoveFocusHistory, currentUrl);

      if (module.type === MODULE_TYPE.JS) {
        yield call(handleJSModuleEntityRedirect, module.actionId);
      } else {
        yield call(handleQueryModuleEntityRedirect, module.actionId);
      }

      analytics.deleteModule(action.payload.id);

      if (!!action.payload.onSuccess) {
        action.payload.onSuccess();
      } else if (!isNewPackageExplorerEnabled) {
        history.push(currentPackageEditorURL());
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.DELETE_MODULE_ERROR,
      payload: { error },
    });
  }
}

export function* saveModuleNameOldSaga(
  action: ReduxAction<SaveModuleNamePayload>,
) {
  try {
    yield put(
      startModuleActionTracking(ReduxActionTypes.SAVE_MODULE_NAME_INIT),
    );
    const { id, name } = action.payload;
    const module: ReturnType<typeof getModuleById> = yield select(
      getModuleById,
      id,
    );
    const currentModuleId: string = yield select(getCurrentModuleId);
    const currentBaseModuleId: string = yield select(getCurrentBaseModuleId);
    const metadata: ModuleMetadata = yield select(getModulesMetadataById, id);

    if (!module) {
      throw Error("Saving module name failed. Module not found.");
    }

    const refactorPayload: RefactorModulePayload = {
      moduleId: id,
      oldName: module.name,
      newName: name,
    };

    const response: ApiResponse<Module> = yield call(
      ModuleApi.refactorModule,
      refactorPayload,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put(setupModule({ baseModuleId: currentBaseModuleId }));

      yield put({
        type: ReduxActionTypes.REFACTOR_MODULE_REFERENCES_UPDATE,
        payload: refactorPayload,
      });

      yield put({
        type: ReduxActionTypes.SAVE_MODULE_NAME_SUCCESS,
        payload: {
          ...response.data,
          ...metadata,
        },
      });

      yield call(waitForSetupModule);

      // When different module name is modified using the entity explorer
      // calling fetchModuleEntitiesSaga will override current modules's entities in reducer
      if (currentModuleId === id) {
        yield call(fetchModuleEntitiesSaga, {
          payload: { moduleId: id },
          type: ReduxActionTypes.FETCH_MODULE_ENTITIES_INIT,
        });
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.SAVE_MODULE_NAME_ERROR,
      payload: { error },
    });
  } finally {
    yield put(endModuleActionTracking(ReduxActionTypes.SAVE_MODULE_NAME_INIT));
  }
}

export function* updateModuleInputsSaga(
  action: ReduxAction<UpdateModuleInputsPayload>,
) {
  try {
    yield put(
      startModuleActionTracking(ReduxActionTypes.UPDATE_MODULE_INPUTS_INIT),
    );

    yield call(identifyAndUpdateReferencesOnInputUpdateSaga, action);

    const isUpdatingModuleReferencesSuccess: boolean = yield call(
      waitForUpdatingModuleReferences,
    );

    if (!isUpdatingModuleReferencesSuccess) {
      return;
    }

    const { diff, id, inputsForm, moduleEditorType } = action.payload;
    const module: ReturnType<typeof getModuleById> = yield select(
      getModuleById,
      id,
    );
    const metadata: ModuleMetadata = yield select(getModulesMetadataById, id);

    if (!module) {
      throw Error("Saving module inputs failed. Module not found.");
    }

    let response: ApiResponse<Module>;

    if (diff && hasInputNameChanged(diff)) {
      const changes = inputNameDiff(diff[0] as DiffEdit<string, string>);
      const inputId = findInputIdFor(inputsForm, changes.newName);

      if (!inputId) {
        throw new Error("Could not find input to update name");
      }

      const payload = {
        moduleId: id,
        inputId,
        ...changes,
      };

      response = yield call(ModuleApi.refactorModuleInput, payload);
    } else {
      const payload = {
        ...module,
        inputsForm,
      };

      response = yield call(ModuleApi.updateModule, payload);
    }

    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.UPDATE_MODULE_INPUTS_SUCCESS,
        payload: {
          ...response.data,
          ...metadata,
        },
      });

      analytics.updateModule(response.data);

      if (hasInputNameChanged(diff)) {
        yield call(fetchModuleEntitiesSaga, {
          type: ReduxActionTypes.FETCH_MODULE_ENTITIES_INIT,
          payload: { moduleId: id },
        });

        const publicAction: Action = yield select(getModulePublicAction, id);

        if (moduleEditorType === MODULE_EDITOR_TYPE.QUERY) {
          yield put(initialize(QUERY_EDITOR_FORM_NAME, publicAction));
        }

        if (moduleEditorType === MODULE_EDITOR_TYPE.API) {
          yield put(initialize(API_EDITOR_FORM_NAME, publicAction));
        }

        /** This is a dummy action to trigger evaluation for the
         * entities fetched after refactoring.
         */
        yield put({
          type: ReduxActionTypes.REFACTOR_MODULE_INPUT_SUCCESS,
          payload: undefined,
        });
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_MODULE_INPUTS_ERROR,
      payload: {
        error: { message: createMessage(UPDATE_MODULE_INPUT_ERROR) },
      },
    });
  } finally {
    yield put(
      endModuleActionTracking(ReduxActionTypes.UPDATE_MODULE_INPUTS_INIT),
    );
  }
}

export function* fetchModuleEntitiesSaga(
  action: ReduxAction<FetchModuleActionsPayload>,
) {
  try {
    const response: ApiResponse<FetchModuleEntitiesResponse> = yield call(
      ModuleApi.getModuleEntities,
      action.payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.FETCH_MODULE_ENTITIES_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_MODULE_ENTITIES_ERROR,
      payload: { error },
    });
  }
}

/**
 * Creates an action with specific datasource created by a user
 * @param action
 */
export function* createQueryModuleOldSaga(
  action: ReduxAction<CreateQueryModulePayload>,
) {
  try {
    const {
      apiType = PluginPackageName.REST_API,
      datasourceId,
      from,
      packageId,
    } = action.payload;
    const allModules: ModulesReducerState = yield select(getAllModules);
    const moduleMetadata: Record<string, ModuleMetadata> =
      yield select(getModulesMetadata);
    const newActionName = createNewModuleName(allModules, MODULE_PREFIX.QUERY);

    const defaultAction: Partial<Action> = datasourceId
      ? yield call(createDefaultActionPayloadWithPluginDefaults, {
          datasourceId,
          from,
          newActionName,
        } as CreateActionDefaultsParams)
      : yield call(createDefaultApiActionPayload, {
          apiType,
          from,
          newActionName,
        } as CreateApiActionDefaultsParams);

    const { name, ...restAction } = defaultAction;
    const payload: CreateModulePayload = {
      packageId,
      name,
      type: MODULE_TYPE.QUERY,
      inputsForm: [generateDefaultInputSection()],
      entity: {
        type: MODULE_ENTITY_TYPE.ACTION,
        ...restAction,
      },
    };

    const response: ApiResponse<Module> = yield call(
      ModuleApi.createModule,
      payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.CREATE_QUERY_MODULE_SUCCESS,
        payload: {
          ...response.data,
          ...moduleMetadata[response.data.id],
        },
      });

      analytics.createModule(response.data);

      history.push(moduleEditorURL({ baseModuleId: response.data.baseId }));
      yield fork(resetDebuggerLogs);
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.CREATE_QUERY_MODULE_ERROR,
      payload: { error },
    });
  }
}

export function* createJSModuleOldSaga(
  action: ReduxAction<CreateJSModulePayload>,
) {
  try {
    const { packageId } = action.payload;
    const allModules: ModulesReducerState = yield select(getAllModules);
    const workspaceId: string = yield select(getCurrentWorkspaceId);
    const moduleMetadata: Record<string, ModuleMetadata> =
      yield select(getModulesMetadata);
    const { actions, body, variables } = createDummyJSCollectionActions(
      workspaceId,
      {
        packageId,
      },
    );
    const newModuleName = createNewModuleName(allModules, MODULE_PREFIX.JS);

    const defaultJSObject: CreateJSCollectionRequest =
      yield generateDefaultJSObject({
        name: newModuleName,
        workspaceId,
        actions,
        body,
        variables,
      });

    const payload: CreateModulePayload = {
      packageId,
      name: newModuleName,
      type: MODULE_TYPE.JS,
      entity: {
        type: MODULE_ENTITY_TYPE.JS_OBJECT,
        ...defaultJSObject,
      },
    };

    const response: ApiResponse<Module> = yield call(
      ModuleApi.createModule,
      payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.CREATE_JS_MODULE_SUCCESS,
        payload: {
          ...response.data,
          ...moduleMetadata[response.data.id],
        },
      });

      analytics.createModule(response.data);

      history.push(moduleEditorURL({ baseModuleId: response.data.baseId }));
      yield fork(resetDebuggerLogs);
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.CREATE_JS_MODULE_ERROR,
      payload: { error },
    });
  }
}

/**
 * Creates an action with specific datasource created by a user
 * @param action
 */
export function* createQueryModuleSaga(
  action: ReduxAction<CreateQueryModulePayload>,
) {
  try {
    const {
      apiType = PluginPackageName.REST_API,
      datasourceId,
      from,
      packageId,
    } = action.payload;
    const allModules: ModulesReducerState = yield select(getAllModules);
    const newActionName = createNewModuleName(allModules, MODULE_PREFIX.QUERY);

    const defaultAction: Partial<Action> = datasourceId
      ? yield call(createDefaultActionPayloadWithPluginDefaults, {
          datasourceId,
          from,
          newActionName,
        } as CreateActionDefaultsParams)
      : yield call(createDefaultApiActionPayload, {
          apiType,
          from,
          newActionName,
        } as CreateApiActionDefaultsParams);

    const { name, ...restAction } = defaultAction;
    const payload: CreateModulePayload = {
      packageId,
      name,
      type: MODULE_TYPE.QUERY,
      inputsForm: [generateDefaultInputSection()],
      entity: {
        type: MODULE_ENTITY_TYPE.ACTION,
        ...restAction,
      },
    };

    const response: ApiResponse<CreateModuleResponse> = yield call(
      ModuleApi.createModule,
      payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      const module = response.data;
      const plugins: Plugin[] = yield select(
        (state) => state.entities.plugins.list,
      );
      const pluginGroups = keyBy(plugins, "id");
      const plugin = pluginGroups[module.metadata.pluginId];
      const entityUrl = getEntityURL({
        baseId: module.metadata.publicEntity.baseId,
        plugin,
        baseModuleId: module.baseId || "",
      });

      yield put({
        type: ReduxActionTypes.CREATE_QUERY_MODULE_SUCCESS,
        payload: response.data,
      });

      history.push(entityUrl);
    }

    analytics.createModule(response.data);
    yield fork(resetDebuggerLogs);
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.CREATE_QUERY_MODULE_ERROR,
      payload: { error },
    });
  }
}

export function* createJSModuleSaga(
  action: ReduxAction<CreateJSModulePayload>,
) {
  try {
    const { packageId } = action.payload;
    const allModules: ModulesReducerState = yield select(getAllModules);
    const workspaceId: string = yield select(getCurrentWorkspaceId);
    const { actions, body, variables } = createDummyJSCollectionActions(
      workspaceId,
      {
        packageId,
      },
    );
    const newModuleName = createNewModuleName(allModules, MODULE_PREFIX.JS);

    const defaultJSObject: CreateJSCollectionRequest =
      yield generateDefaultJSObject({
        name: newModuleName,
        workspaceId,
        actions,
        body,
        variables,
      });

    const payload: CreateModulePayload = {
      packageId,
      name: newModuleName,
      type: MODULE_TYPE.JS,
      entity: {
        type: MODULE_ENTITY_TYPE.JS_OBJECT,
        ...defaultJSObject,
      },
    };

    const response: ApiResponse<CreateModuleResponse> = yield call(
      ModuleApi.createModule,
      payload,
    );

    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      const module = response.data;
      const plugins: Plugin[] = yield select(
        (state) => state.entities.plugins.list,
      );
      const pluginGroups = keyBy(plugins, "id");
      const plugin = pluginGroups[module.metadata.publicEntity.pluginId];
      const entityUrl = getEntityURL({
        baseId: module.metadata.publicEntity.baseId,
        plugin,
        baseModuleId: module.baseId || "",
      });

      yield put({
        type: ReduxActionTypes.CREATE_JS_MODULE_SUCCESS,
        payload: response.data,
      });

      history.push(entityUrl);
    }

    analytics.createModule(response.data);
    yield fork(resetDebuggerLogs);
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.CREATE_JS_MODULE_ERROR,
      payload: { error },
    });
  }
}

export function* setupModuleSaga(action: ReduxAction<SetupModulePayload>) {
  try {
    const { baseModuleId } = action.payload;

    const module: Module<ModuleLayout> = yield select(
      getModuleByBaseId,
      baseModuleId,
    );

    yield put({
      type: ReduxActionTypes.SET_CURRENT_MODULE,
      payload: module,
    });

    if (module.type === MODULE_TYPE.UI) {
      // Wait for widget config to be loaded before we can generate the canvas payload
      yield call(waitForWidgetConfigBuild);

      // Update the canvas
      yield put(
        initModuleCanvasLayout({
          widgets: flattenDSL(module?.layouts?.[0]?.dsl || {}),
        }),
      );
    }

    yield put({
      type: ReduxActionTypes.FETCH_MODULE_ENTITIES_INIT,
      payload: { moduleId: module.id },
    });

    yield put({
      type: ReduxActionTypes.SETUP_MODULE_INSTANCE_INIT,
      payload: {
        contextId: module.id,
        contextType: ModuleInstanceCreatorType.MODULE,
        viewMode: false,
      },
    });

    yield put({
      type: ReduxActionTypes.SETUP_MODULE_SUCCESS,
    });

    // To start eval for new module
    yield put(fetchAllModuleEntityCompletion([executePageLoadActions()]));
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.SETUP_MODULE_ERROR,
      payload: { error },
    });
  }
}

export function* handleRefactorJSActionNameSaga(
  data: ReduxAction<{
    refactorAction: RefactorAction;
    actionCollection: JSCollection;
  }>,
) {
  const { refactorAction } = data.payload;

  if (refactorAction.moduleId) {
    const requestData: UpdateCollectionActionNameRequest = {
      ...data.payload.refactorAction,
      actionCollection: data.payload.actionCollection,
      contextType: "MODULE",
    };

    // call to refactor action
    try {
      const refactorResponse: ApiResponse =
        yield JSActionAPI.updateJSCollectionActionRefactor(requestData);

      const isRefactorSuccessful: boolean =
        yield validateResponse(refactorResponse);

      if (isRefactorSuccessful) {
        const module: Module = yield select(
          getModuleById,
          refactorAction.moduleId,
        );

        const updatedModule = klona(module);

        if (updatedModule.inputsForm?.[0]?.children) {
          updatedModule.inputsForm[0].children = (
            updatedModule.inputsForm[0]?.children || []
          ).map((input) => {
            if (input.label === refactorAction.oldName) {
              input.label = refactorAction.newName;
            }

            return input;
          });

          const response: ApiResponse<Module> = yield call(
            ModuleApi.updateModule,
            updatedModule,
          );

          const isValidResponse: boolean = yield validateResponse(response);

          if (isValidResponse) {
            yield put({
              type: ReduxActionTypes.UPDATE_MODULE_SUCCESS,
              payload: response.data,
            });
          }
        }

        yield call(fetchModuleEntitiesSaga, {
          payload: { moduleId: refactorAction.moduleId },
          type: ReduxActionTypes.FETCH_MODULE_ENTITIES_INIT,
        });

        yield put({
          type: ReduxActionTypes.REFACTOR_JS_ACTION_NAME_SUCCESS,
          payload: { collectionId: data.payload.actionCollection.id },
        });
      }
    } catch (error) {
      yield put({
        type: ReduxActionErrorTypes.REFACTOR_JS_ACTION_NAME_ERROR,
        payload: { collectionId: data.payload.actionCollection.id },
      });
    }
  }
}

export function* generateJSModuleInputs(
  actions: JSCollection["actions"] = [],
  moduleId: string,
) {
  const module: Module = yield select(getModuleById, moduleId);

  if (!module) {
    return {
      isInputsUpdated: false,
      inputsForm: [],
    };
  }

  try {
    let currentInputsForm = klona(module?.inputsForm);
    const inputs = currentInputsForm?.[0]?.children || [];
    const inputsByLabel = Object.fromEntries(
      inputs.map((input) => [input.label, input]),
    );
    const oldFunctions = new Set(objectKeys(inputsByLabel));
    let isInputsUpdated = false;
    const updatedInputs: ModuleInput[] = [];
    const existingIds = inputs.map(({ id }) => id);

    // Add/update inputs
    actions.forEach(({ actionConfiguration, name }) => {
      const newDefaultValue = (actionConfiguration.jsArguments || [])
        ?.map(({ value }) => value)
        .filter(Boolean);

      const oldDefaultValue = inputsByLabel[name]?.defaultValue
        ? JSON.parse(inputsByLabel[name]?.defaultValue || "")
        : [];

      if (!oldFunctions.has(name) || !equal(newDefaultValue, oldDefaultValue)) {
        isInputsUpdated = true;
      }

      updatedInputs.push({
        id: inputsByLabel[name]?.id || generateUniqueId(existingIds),
        label: name,
        defaultValue:
          newDefaultValue === undefined ? "" : JSON.stringify(newDefaultValue),
        propertyName: `inputs.${name}`,
        controlType: "INPUT_TEXT",
      });

      // Removing function names that are processed.
      oldFunctions.delete(name);
    });

    // There are some unprocessed functions so it's an indication that these functions are removed
    if (oldFunctions.size > 0) {
      isInputsUpdated = true;
    }

    if (!currentInputsForm) {
      currentInputsForm = [
        {
          sectionName: "",
          id: generateUniqueId(),
          children: [],
        },
      ];
    }

    set(currentInputsForm, "0.children", updatedInputs);

    return {
      isInputsUpdated,
      inputsForm: currentInputsForm,
    };
  } catch (e) {
    return {
      isInputsUpdated: false,
      inputsForm: [],
    };
  }
}

export function* updateJSModuleInputsSaga(
  action: ReduxAction<{ data: JSCollection }>,
) {
  try {
    yield put(startModuleActionTracking(ReduxActionTypes.UPDATE_MODULE_INIT));

    const jsCollection = action.payload.data;
    const moduleId = jsCollection.moduleId;

    // Not part of module or module instance or is private entity then skip processing
    if (!moduleId || !jsCollection.isPublic) return;

    yield put({
      type: ReduxActionTypes.UPDATE_MODULE_INIT,
    });

    const module: Module = yield select(getModuleById, moduleId);
    const { inputsForm, isInputsUpdated }: ReturnGenerateJSModuleInputs =
      yield generateJSModuleInputs(jsCollection.actions, moduleId);

    if (isInputsUpdated) {
      const payload: Module = {
        ...module,
        inputsForm,
      };

      const response: ApiResponse<Module> = yield call(
        ModuleApi.updateModule,
        payload,
      );

      const isValidResponse: boolean = yield validateResponse(response);

      if (isValidResponse) {
        yield put({
          type: ReduxActionTypes.UPDATE_MODULE_SUCCESS,
          payload: response.data,
        });
      }
    } else {
      yield put({
        type: ReduxActionTypes.UPDATE_MODULE_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_MODULE_ERROR,
      payload: { error },
    });
  } finally {
    yield put(endModuleActionTracking(ReduxActionTypes.UPDATE_MODULE_INIT));
  }
}

function* getShouldComputeReferences() {
  const showQueryModules: boolean = yield select(getShowQueryModule);
  const isPackageEditor = window.location.pathname.startsWith("/pkg");

  return showQueryModules && isPackageEditor;
}

export function* initModuleReferenceSaga(
  action: ReduxAction<FetchPackageResponse>,
) {
  const shouldComputeReferences: boolean = yield call(
    getShouldComputeReferences,
  );

  if (!shouldComputeReferences) return;

  const { modules, modulesMetadata } = action.payload;
  const metadata = keyBy(modulesMetadata, "moduleId");
  const references: Record<string, Set<string>> = {};

  for (const module of modules) {
    references[module.id] = new Set();

    if (module.type === MODULE_TYPE.QUERY) {
      const publicAction = metadata[module.id]?.publicEntity as Action;

      if (publicAction) {
        const { references: currentReferences }: ReferenceReturnType =
          yield call(findReferencesInQueryModule, module, publicAction);

        references[module.id] = currentReferences;
      }
    } else if (module.type === MODULE_TYPE.JS) {
      const publicJSCollection = metadata[module.id]
        ?.publicEntity as JSCollection;

      if (publicJSCollection) {
        const { references: currentReferences }: ReferenceReturnType =
          yield call(findReferencesInJSModule, publicJSCollection);

        references[module.id] = currentReferences;
      }
    }
  }

  yield put({
    type: ReduxActionTypes.INIT_MODULE_REFERENCES,
    payload: {
      references,
    },
  });
}

export function* identifyForModuleInputsForm(inputsForm: Module["inputsForm"]) {
  const shouldComputeReferences: boolean = yield call(
    getShouldComputeReferences,
  );

  if (!shouldComputeReferences) return;

  const [parentSection] = inputsForm;
  let references: string[] = [];
  let isError = false;

  for (const input of parentSection.children) {
    const { jsSnippets } = getDynamicBindings(input.defaultValue);

    for (const jsSnippet of jsSnippets) {
      const {
        isError: currentIsError,
        references: currentReferences,
      }: ReferenceReturnType = yield call(
        extractReferencesFromCodeSaga,
        jsSnippet,
      );

      references = references.concat([...currentReferences]);

      if (currentIsError) {
        isError = true;
      }
    }
  }

  return {
    references,
    isError,
  };
}

export function* findReferencesInJSModule(publicJSCollection?: JSCollection) {
  const shouldComputeReferences: boolean = yield call(
    getShouldComputeReferences,
  );

  if (!shouldComputeReferences) return;

  const references = new Set<string>();

  if (publicJSCollection) {
    const body = (publicJSCollection.body || "").replace("export default", "");

    return (yield call(
      extractReferencesFromCodeSaga,
      body,
    )) as ReferenceReturnType;
  }

  return {
    references,
    isError: true,
  };
}

export function* findReferencesInQueryModule(
  module: Module,
  publicAction?: Action,
) {
  const shouldComputeReferences: boolean = yield call(
    getShouldComputeReferences,
  );

  if (!shouldComputeReferences) return;

  const references = new Set();
  let isError = false;

  if (publicAction) {
    for (const path of publicAction?.dynamicBindingPathList || []) {
      const { key } = path;
      const code = get(publicAction.actionConfiguration, key, "");

      const { jsSnippets } = getDynamicBindings(code);

      for (const jsSnippet of jsSnippets) {
        const {
          isError: currentIsError,
          references: currentReferences,
        }: ReferenceReturnType = yield call(
          extractReferencesFromCodeSaga,
          jsSnippet,
        );

        currentReferences.forEach((r) => references.add(r));

        if (currentIsError) {
          isError = true;
        }
      }
    }
  }

  const {
    isError: isErrorInInput,
    references: referencesInInputs,
  }: ReferenceReturnType = yield call(
    identifyForModuleInputsForm,
    module.inputsForm,
  );

  if (isErrorInInput) {
    isError = true;
  }

  referencesInInputs.forEach((r) => references.add(r));

  return {
    references,
    isError,
  };
}

export function* identifyAndUpdateReferencesOnInputUpdateSaga(
  action: ReduxAction<UpdateModuleInputsPayload>,
) {
  const shouldComputeReferences: boolean = yield call(
    getShouldComputeReferences,
  );

  if (!shouldComputeReferences) return;

  const { id, inputsForm } = action.payload;
  const module: Module = klona((yield select(getModuleById, id)) as Module);
  const publicAction: Action = yield select(getModulePublicAction, id);

  module.inputsForm = inputsForm;

  const { isError, references }: ReferenceReturnType = yield call(
    findReferencesInQueryModule,
    module,
    publicAction,
  );

  if (isError) return;

  yield put({
    type: ReduxActionTypes.SET_MODULE_REFERENCES,
    payload: {
      moduleId: id,
      references,
    },
  });

  yield call(modifyModuleReferencesByNameSaga);
}

export function* identifyAndUpdateReferencesOnJSBodyUpdateSaga(
  action: ReduxAction<{ data: JSCollection }>,
) {
  const shouldComputeReferences: boolean = yield call(
    getShouldComputeReferences,
  );

  if (!shouldComputeReferences) return;

  const { body, moduleId } = action.payload.data;
  const code = (body || "").replace("export default", "");
  const { isError, references }: ReferenceReturnType = yield call(
    extractReferencesFromCodeSaga,
    code,
  );

  if (isError) return;

  yield put({
    type: ReduxActionTypes.SET_MODULE_REFERENCES,
    payload: {
      moduleId,
      references,
    },
  });

  yield call(modifyModuleReferencesByNameSaga);
}

export function* identifyAndUpdateReferencesOnActionPropertyUpdateSaga(
  action: ReduxAction<SetActionPropertyPayload>,
) {
  const shouldComputeReferences: boolean = yield call(
    getShouldComputeReferences,
  );

  if (!shouldComputeReferences) return;

  const { actionId, propertyName, value } = action.payload;
  const query: Action = klona(
    (yield select((state) => {
      const action = find(
        state.entities.actions,
        (a) => a.config.id === actionId,
      );

      return action ? action.config : undefined;
    })) as Action,
  );

  if (query && query.isPublic) {
    const module: Module = yield select(getModuleById, query.moduleId || "");

    // Since we are catching the update of action early, these properties might not
    // be set.
    set(query, propertyName, value);

    if (isDynamicValue(value)) {
      if (!query.dynamicBindingPathList) {
        query.dynamicBindingPathList = [];
      }

      const { propertyPath } = getEntityNameAndPropertyPath(propertyName);

      query.dynamicBindingPathList.push({ key: propertyPath });
    }

    const { isError, references }: ReferenceReturnType = yield call(
      findReferencesInQueryModule,
      module,
      query,
    );

    if (isError) return;

    yield put({
      type: ReduxActionTypes.SET_MODULE_REFERENCES,
      payload: {
        moduleId: module.id,
        references,
      },
    });

    yield call(modifyModuleReferencesByNameSaga);
  }
}

export function* createUIModuleSaga(
  action: ReduxAction<CreateUIModulePayload>,
) {
  try {
    const showUIModule: boolean = yield select(getShowUIModule);

    if (!showUIModule) return;

    const { packageId } = action.payload;
    const allModules: ModulesReducerState = yield select(getAllModules);
    const newModuleName = createNewModuleName(allModules, MODULE_PREFIX.UI);
    const baseDSL = createBaseModuleDSL();
    const layouts = [{ dsl: baseDSL, layoutOnLoadActions: [] }];

    const payload: CreateModulePayload = {
      packageId,
      name: newModuleName,
      type: MODULE_TYPE.UI,
      layouts,
      inputsForm: [generateDefaultInputSection()],
    };

    const response: ApiResponse<Module> = yield call(
      ModuleApi.createModule,
      payload,
    );

    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.CREATE_UI_MODULE_SUCCESS,
        payload: {
          module: response.data,
          widgets: flattenDSL(layouts[0].dsl as unknown as NestedDSL<unknown>),
        },
      });

      // Uncomment when IDE is ready
      // history.push(
      //   builderURL({
      //     baseModuleId: response.data.baseId,
      //   }),
      // );
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.CREATE_UI_MODULE_ERROR,
      payload: { error },
    });
  }
}

export function* updateModuleLayoutSaga(
  action: ReduxAction<{ widgets: { [widgetId: string]: WidgetProps } }>,
) {
  try {
    const { widgets } = action.payload;
    const currentModuleId: string = yield select(getCurrentModuleId);
    const currentModule: Module = yield select(getModuleById, currentModuleId);
    const dsl = nestDSL(widgets);

    const updatedModule: Module<ModuleLayout> = {
      ...currentModule,
      layouts: [
        {
          dsl,
          layoutOnLoadActions:
            get(currentModule, "layouts[0].layoutOnLoadActions", []) || [],
        },
      ],
    };

    const response: ApiResponse<Module> = yield call(
      ModuleApi.updateModule,
      updatedModule,
    );

    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.UPDATE_MODULE_LAYOUT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_MODULE_LAYOUT_ERROR,
      payload: { error },
    });
  }
}

export function* InitModulesSaga() {
  const featureFlags: FeatureFlags = yield select(selectFeatureFlags);
  const isNewPackageExplorerEnabled =
    featureFlags.release_segmented_package_explorer_enabled;

  yield all([
    takeLatest(ReduxActionTypes.DELETE_MODULE_INIT, deleteModuleSaga),
    takeLatest(ReduxActionTypes.SAVE_MODULE_NAME_INIT, saveModuleNameOldSaga),
    takeLatest(
      ReduxActionTypes.CREATE_QUERY_MODULE_INIT,
      isNewPackageExplorerEnabled
        ? createQueryModuleSaga
        : createQueryModuleOldSaga,
    ),
    takeLatest(ReduxActionTypes.CREATE_UI_MODULE_INIT, createUIModuleSaga),
    takeLatest(
      ReduxActionTypes.FETCH_MODULE_ENTITIES_INIT,
      fetchModuleEntitiesSaga,
    ),
    takeLatest(
      ReduxActionTypes.CREATE_JS_MODULE_INIT,
      isNewPackageExplorerEnabled ? createJSModuleSaga : createJSModuleOldSaga,
    ),
    takeLatest(
      ReduxActionTypes.UPDATE_MODULE_INPUTS_INIT,
      updateModuleInputsSaga,
    ),
    takeLatest(ReduxActionTypes.SETUP_MODULE_INIT, setupModuleSaga),
    takeEvery(
      ReduxActionTypes.REFACTOR_JS_ACTION_NAME,
      handleRefactorJSActionNameSaga,
    ),
    takeLatest(
      ReduxActionTypes.UPDATE_JS_ACTION_SUCCESS,
      updateJSModuleInputsSaga,
    ),
    takeLatest(
      ReduxActionTypes.UPDATE_JS_ACTION_BODY_SUCCESS,
      identifyAndUpdateReferencesOnJSBodyUpdateSaga,
    ),
    takeLatest(
      ReduxActionTypes.SET_ACTION_PROPERTY,
      identifyAndUpdateReferencesOnActionPropertyUpdateSaga,
    ),
    takeLatest(
      ReduxActionTypes.SETUP_MODULE_SUCCESS,
      modifyModuleReferencesByNameSaga,
    ),
    takeLatest(ReduxActionTypes.FETCH_PACKAGE_SUCCESS, initModuleReferenceSaga),
    takeLatest(
      ReduxActionTypes.UPDATE_MODULE_LAYOUT_INIT,
      updateModuleLayoutSaga,
    ),
  ]);
}

export default function* modulesSagas() {
  yield takeLatest(
    ReduxActionTypes.END_CONSOLIDATED_PAGE_LOAD,
    InitModulesSaga,
  );
}
